import { createApi, FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from '../../services/AxiosBaseQuery'
import config from '@configs/index'
import axios from 'axios'

export type NewsletterSubscriptionResponse = {
  code: string
  message: string
}

export type NewsletterSubscriptionBody = {
  id?: string
  newsletter_id: string
  firstName?: string
  email: string
  from?: string
}

type NewsletterUpdateSyncPrivacyFlagArgs = {
  EmailAddress: string
  customersubscribestatus: 1 | 0
  storeId: number
  locale: string
}

const CONTACT_SUBSCRIBE_TYPE = 'NEWSLETTER'

export const newsletterApi = createApi({
  reducerPath: 'newsletterApi',
  baseQuery: axiosBaseQuery({
    baseUrl: '/store/{storeId}',
  }),

  endpoints: build => ({
    subscribeNewsletter: build.mutation<NewsletterSubscriptionResponse, NewsletterSubscriptionBody>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: '/newsletter',
          method: 'POST',
          body: args,
        })

        if (result.error) {
          return { error: result.error as FetchBaseQueryError }
        }
        return { data: result.data as NewsletterSubscriptionResponse }
      },
    }),
    unsubscribeNewsletter: build.mutation<void, string>({
      query: email => ({
        url: '/newsletter/optOut',
        method: 'POST',
        body: {
          email,
        },
      }),
    }),
    updateNewsletterPrivacyFlag: build.mutation<void, NewsletterUpdateSyncPrivacyFlagArgs>({
      query: ({ EmailAddress, customersubscribestatus, storeId, locale }) => {
        const allowedLocale = config?.wunderkindNewsletterPrivacyLocales.includes(locale)
        const wunderkindEnabled = config?.wunderkindEnabled
        if (!EmailAddress || !storeId || !allowedLocale || !wunderkindEnabled) {
          return { url: '', method: 'GET' }
        }

        return {
          url: `/ContactSubscription/SynchFromExternalService`,
          method: 'PUT',
          body: {
            EmailAddress,
            customersubscribestatus,
            ContactSubscrType: CONTACT_SUBSCRIBE_TYPE,
          },
        }
      },
    }),
  }),
})

export const {
  useSubscribeNewsletterMutation,
  useUnsubscribeNewsletterMutation,
  useUpdateNewsletterPrivacyFlagMutation,
} = newsletterApi

type NewsletterPrivacyFlagArgs = {
  EmailAddress: string
  storeId: number
  locale: string
}

const getNewsletterPrivacyConfigDetails = (locale: string, storeId: number) => {
  const [host, transCtx, allowedLocale, wunderkindEnabled] = [
    config?.transactionHost,
    config?.transactionContext,
    config?.wunderkindNewsletterPrivacyLocales.includes(locale),
    config?.wunderkindEnabled,
  ]
  const url = `${host}${transCtx}/store/${storeId}/ContactSubscription/SynchFromExternalService`
  return { host, transCtx, allowedLocale, wunderkindEnabled, url }
}

type NewsletterPrivacyFlagReturn = {
  checkContactStatusWCSResult: boolean
}

export const getNewsletterPrivacyFlag = async ({
  EmailAddress,
  storeId,
  locale,
}: NewsletterPrivacyFlagArgs): Promise<NewsletterPrivacyFlagReturn | undefined> => {
  const { host, transCtx, allowedLocale, wunderkindEnabled, url } = getNewsletterPrivacyConfigDetails(locale, storeId)
  if (!EmailAddress || !storeId || !allowedLocale || !wunderkindEnabled || !host || !transCtx) return

  const payload = {
    EmailAddress,
    ContactSubscrType: CONTACT_SUBSCRIBE_TYPE,
  }
  const response = await axios.post(url, payload)

  return response?.data
}
